// /src/pages/solution_cloudflare.js
import React from "react"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const base_strs = {
  description: 'cloudflareのソリューション記事一覧 :',
  keywords: 'cloudflare,ソリューション,記事一覧,'
};

export default function Home({ data }) {
  const edges = data.allContentfulTechBlog.edges;
  var titles_str = "";
  edges.map(edge =>
    titles_str = titles_str + edge.node.title + ","
  )
  const description_str = base_strs.description + titles_str;
  const keywords_str = base_strs.keywords + titles_str;
  return (
    <Layout>
      <Seo title="CloudFlareソリューション例記事一覧" description={description_str} keywords={keywords_str}/>
      <p className="pad_top2">
      cloudflareのソリューション例記事一覧です、この記事は上から順に読むように作成されています。
      </p>
      {edges.map(edge =>
        <PostLink post={edge.node} />
      )}
    </Layout>
  )
}

export const query = graphql`
query CloudflareSolutionQuery {
  allContentfulTechBlog (filter: {tag: {eq: "cloudflare_solution"}},sort: {fields: page order: ASC}){
    edges {
      node {
        id
        title
        body {
          internal {
            content
          }
        }
        tag
        createdAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
        updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
      }
    }
  }
}
`